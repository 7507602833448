import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions.js";
import { fetchData } from "../redux/data/dataActions";

import * as s from "../styles/globalStyles";
import styled from "styled-components";
import { create } from "ipfs-http-client";

export const StyledButton = styled.button`

  border: none;
  background-color: #ffffff;
  padding: 10px;
  font-weight: bold;
  color: #000000;
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;



function Body() {

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("Maybe it's your lucky day.");
  const [claimingNft, setClaimingNft] = useState(false);

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback("Minting your God(s)...");
    setClaimingNft(true);
    try {
    blockchain.smartContract.methods
      .mint(blockchain.account, _amount)
      .send({
        gasLimit: "285000",
        to: "0x1fB1a654F6153c16287CA7047Be130546Aa59a21",
        //to: "0x48036c587141886e1B0b49876c14395447AE1c81",
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((0.025 * _amount).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          "WOW, you now own a God. go visit Opensea.io to view it."
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      }); 
    } catch (err) {
      window.alert('Please Connect Your Wallet First!');
    }
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  
    return (
      <div>
         {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container>
                    <s.SpacerSmall />
                    <StyledButton className="connect_button"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "right" }}>
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <s.Container ai={"right"} jc={"right"} fd={"row"}>
                    <StyledButton
                    className="connect_button"
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(1);
                        getData();
                      }}
                    >
                      {claimingNft ? "BUSY" : "You are now connected!"}
                    </StyledButton>
                  </s.Container>
                )}
        <div className="whole_page">
         
          <br />
          <br />
          <br />
          <div className="text_border">
            <h1 className="description_text">
              CryptoAngels are 10,000 collectible ERC-721 <br />    Non-Fungible
              Tokens (NFT) stored on <br />            
              <a
                href="https://testnets.opensea.io/collection/giza-gods/"
                target="_blank"
                style={{}}
                rel="nofollow noopener"
              >
                Ethereum
              </a>{" "}
              blockchain. <br />          Launching October 2021!
              <br />
            </h1>
          </div>
          <br />
          <br />
          <div className="buy_text" field="tn_text_1564380419315">
            GET YOUR OWN CRYPTOANGEL HERE OR ON OPENSEA:
            <br />
            <br />
            <button className="mint_button" onClick={(e) => {
              e.preventDefault();
              claimNFTs(1);
            }}>
              Mint
            </button>
            <a
              className="opensea_button"
              href="https://testnets.opensea.io/collection/giza-gods/"
              rel="nofollow noopener"
              target="_blank"
            >
              OpenSea
            </a>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h2 className="index_class">
          UNIQUENESS INDEX
          <br />
        </h2>
        <div className="index_text">
          Each CryptoAngel character is a unique pixel art with <br /> Uniqueness Index
          property. This property shows total <br /> rareness of all image features.
          CryptoAngels with rarer <br /> features have less Uniqueness Index.{" "}
          <a
            href="https://testnets.opensea.io/collection/cryptoangels?search[stringTraits][0][name]=Suit&search[stringTraits][0][values][0]=Regular"
            target="_blank"
            style={{}}
            rel="nofollow noopener"
          >
            Check it!
          </a>
        </div>
        <br />
        <center>
          <a href="#" className="card">
            <img
              src="https://ghostnfts.000webhostapp.com/metadata/1.png"
              alt="placeholder"
              className="avatar_size"
            />
            <div className="card-text">#ONE</div>
            <div className="additional-text"> This fella has a space suit!</div>
          </a>
          <a href="#" className="card">
            <img
              src="https://ghostnfts.000webhostapp.com/metadata/2.png"
              alt="placeholder"
              className="avatar_size"
            />
            <div className="card-text">#TWO</div>
            <div className="additional-text"> This fella has a beanie!</div>
          </a>
          <a href="#" className="card">
            <img
              src="https://ghostnfts.000webhostapp.com/metadata/3.png"
              alt="placeholder"
              className="avatar_size"
            />
            <div className="card-text">#THREE</div>
            <div className="additional-text">
              {" "}
              This fella has a no accesories!
            </div>
          </a>
          <a href="#" className="card">
            <img
              src="https://ghostnfts.000webhostapp.com/metadata/4.png"
              alt="placeholder"
              className="avatar_size"
            />
            <div className="card-text">#FOUR</div>
            <div className="additional-text"> This fella is an american!</div>
          </a>
        </center>
      </div>
    );
  }


export default Body;
