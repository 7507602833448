import React, { useEffect, useState, useRef } from "react";
import Parser from 'html-react-parser'
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions.js";
import { fetchData } from "../redux/data/dataActions";
import ReactAnime from 'react-animejs'
import phase0 from '../images/phase0.png'
import phase1 from '../images/phase1.png'
import phase2 from '../images/phase2.png'
import phase3 from '../images/phase3.png'
import phase4 from '../images/phase4.png'
import phase5 from '../images/phase5.png'
import PH1 from '../images/Pharaoh_1.gif'
import PH2 from '../images/Pharaoh_2.png'
import t1 from '../images/Anubis_1.png'
import t2 from '../images/Anubis_2.png'
import t3 from '../images/Cleopatra_2.png'
import * as s from "../styles/globalStyles";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
const { Anime, stagger } = ReactAnime


function Main2() {


  const [mintButton, setMintButton] = useState('');
  const [isDate, setIsDate] = useState(false);







  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("Maybe it's your lucky day.");
  const [claimingNft, setClaimingNft] = useState(false);

  const [quantitySelector, selectQuantity] = useState("1");

  const handleQuantity = (e) => {
    selectQuantity(e.target.value)
  }

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback("Minting your God Of Giza...");
    setClaimingNft(true);

    {/* ------------------------------BELOW HATC CODED COST MUST CHANGE ------------------------------*/ }

    try {
      blockchain.smartContract.methods
        .mint(blockchain.account, _amount)
        .send({
          gasLimit: "285000",
          to: "0x1fB1a654F6153c16287CA7047Be130546Aa59a21",
          from: blockchain.account,
          
          value: blockchain.web3.utils.toWei((0.025 * _amount).toString(), "ether"),
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          setFeedback(
            "WOW, you now own a God Of Giza. go visit Opensea.io to view it."
          );
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });
    } catch (err) {
      window.alert('Please Connect Your Wallet First!');
    }
  };
  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };


  return (
    <div>
      <link rel="stylesheet" href="main.css" />
      <meta charSet="utf-8" />
      <link rel="icon" href="./Logo.svg" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <link rel="manifest" href="/manifest.json" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <title>Gods Of Giza</title>

      <div id="root">
        <div className="styles_page__1f-kE">
          <header className="styles_header__1GCLu">
            <nav className="styles_side_swipe__1hE_v">
              <ul className="styles_unordered__3CR8E">
                <li>
                  <a href="#about" className="styles_link_active__2p7bx">
                    About
                  </a>
                </li>
                <li>
                  <a href="#community">Community</a>
                </li>
                <li>
                  <a href="#roadmap">Roadmap</a>
                </li>
                <li>
                  <a href="#faq">FAQs</a>
                </li>
               
              </ul>
              <div className="styles_cross__2sUuB">X</div>
            </nav>
            <div className="styles_logopair__23DMQ">
              <div className="styles_hamburger__bj5l7">

              </div>
              <Anime
                initial={[
                  {
                    targets: '.letter',
                    opacity: 1,
                    translateY: 10,
                    rotate: {
                      value: 360,
                      duration: 5000,
                      easing: 'easeInExpo'
                    },

                    translateX: [-10, 30]
                  }
                ]}
              >
                <svg viewBox="0 0 1320 300" className='logoSVG' >
                  <text x="50%" y="50%" dy=".35em" text-anchor="middle" className='logoSVG'>
                    GODS OF GIZA
                  </text>
                </svg>
              </Anime>
            </div>
            <div className="styles_menu__2rnm9">
              <nav className="styles_nav__1YM61">
                <ul className="styles_unordered__3CR8E">
                  <li>
                    <a href="#about" className="styles_link_active__2p7bx">
                      About
                    </a>
                  </li>
                  <li>
                    <a href="#community">Community</a>
                  </li>
                  <li>
                    <a href="#roadmap">Roadmap</a>
                  </li>
                  <li>
                    <a href="#faq">FAQs</a>
                  </li>
                  <li>
                  <a href="https://forms.gle/Un7ttT42p47vgRai7" target="_blank" class="custom-btn btn-7"><span>Subscribe</span></a>
                </li>
                </ul>
                <div className="styles_cross__2sUuB">X</div>
              </nav>
            </div>

            {/* ------------------------------CONNECT BUTTON------------------------------*/ }

            {/* <div>
              {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                <s.Container>

                  <button className="wallet-adapter-button wallet-adapter-button-trigger" onClick={(e) => {
                    e.preventDefault();
                    dispatch(connect());
                    getData();
                  }}>Connect</button>
                  <div
                    className="menu-item-deco-line"
                    style={{
                      transform:
                        "translate3d(-100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                      opacity: 1
                    }}
                  ></div>
                  {blockchain.errorMsg !== "" ? (
                    <>
                      <s.SpacerSmall />
                      <s.TextDescription style={{ textAlign: "right", color: "black" }}>
                        {blockchain.errorMsg}
                      </s.TextDescription>
                    </>
                  ) : null}
                </s.Container>
              ) : (
                <s.Container ai={"right"} jc={"right"} fd={"row"}>
                  <button className="wallet-adapter-button wallet-adapter-button-trigger" onClick={(e) => {
                    e.preventDefault();
                    claimNFTs(1);
                    getData();
                  }}>Connected</button>
                  <div
                    className="menu-item-deco-line"
                    style={{
                      transform:
                        "translate3d(-100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                      opacity: 1
                    }}
                  >

                  </div>
                </s.Container>
              )}
            </div> */}

            {/* ------------------------------CONNECT BUTTON------------------------------*/ }

          </header>
          <section className="styles_section__1sL__" id="about">
            <div className="styles_subsection_content__3QiFY">
              <h3 className="styles_subheading__2q9qu">
                A COLLECTION OF{" "}
                <span className="styles_subheading_highlight__1Ezlb">
                  9,999!
                </span>{" "}
                UNIQUE NFTS
              </h3>
              <h1 className="styles_heading__32sCL glowingText">
                Who Are Gods Of <br />
                Giza?
              </h1>
              <p className="styles_para__IZzIB">
                Gods Of Giza is a collection of 9,999 Pharaohs, Deities and
                Godly Creatures themed digital NFT collectibles, Living on the
                Ethereum blockchain. Each of the God NFTs is uniquely
                generated and made up of a unique combination of Jewelry ,
                Weapons, Crowns, Environments and Body Art among others,
                across 192 traits randomized for varying rarities, minted as
                ERC-721 tokens on the Ethereum blockchain, with the media and
                metadata hosted on IPFS. And needless to say, they make for an
                badass profile picture.
              </p>

              {/* ------------------------------MINTING BUTTON------------------------------*/ }

              {/* <label className='quantitySelector'> Quantity </label>
              <input className='quantityInput' type="number" min="1" max="10" defaultValue="1" onChange={handleQuantity} />
              <br />
              <button className="styles_button_primary__3OLD_">
                <span onClick={(e) => {
                  e.preventDefault();
                  claimNFTs(quantitySelector);
                }} >Mint Now</span>
              </button> */}

              {/* ------------------------------MINTING BUTTON------------------------------*/ }

            </div>
            <div className="styles_subsection_image__UFkym">
              <img
                src={"https://i.imgur.com/1GbS5qg.gif"}
                alt
                className="styles_main_image__1s8Ca"
              />
            </div>
          </section>
          <section className="styles_section__1DwYq" id="community">
            <div className="styles_subsection_image__UFkym">
              <img src={"https://i.imgur.com/dNWrQ3g.gif"} alt className="styles_main_image__1s8Ca" />
            </div>
            <div className="styles_subssection__3qEyy">
              <h3 className="glowingText">Community</h3>
              <p>
                We love online communities. We love NFTs and we love Gods of
                Giza.   As well as our own Twitter account – @GodsOfGiza –
                we’re also over on Discord. Join our server to get updates and
                information about the project. We’d love to see you.   Become
                Part of Our Community. Let's join us here!.
              </p>
              <br />
              <a className="twitter-button" href="https://twitter.com/GodsOfGiza" target="_blank">Twitter</a>
              <span> </span>

              <a className="discord-button" href="https://dsc.gg/godsofgiza" target="_blank">Discord</a>
              {/* <button className="discord-button">Discord</button> */}
              
            </div>
          </section>
          <section className="styles_section__1-7Mp" id="roadmap">
            <br />
            <h1 className="styles_heading__1kCwn glowingText">Roadmap</h1>
            <div className="styles_roadmap__content_container__3afc7">
              <div className="styles_roadmap__timeline_bar__3OGAz">
                <div
                  className="styles_roadmap__timeline_bar__scroller__23d3u"
                  style={{
                    top: "0px"
                  }}
                />
              </div>
              <div className="styles_roadmap__content_block__2RfSP">
                <img src={phase0} width='40px' height='40px' />
                <p className="styles_roadmap__content_block_title__2pt_D">
                  Phase 0:
                </p>

                <p className="styles_roadmap__content_block_body__3cP9Z">
                  Launch of Public sale. Following Public Launch, release of
                   companion collection airdrops to holders of Gods of Giza..
                </p>
              </div>
              <div className="styles_roadmap__content_block__2RfSP">
                <img src={phase1} width='40px' height='40px' />
                <p className="styles_roadmap__content_block_title__2pt_D">
                  Phase 1:
                </p>
                <p className="styles_roadmap__content_block_body__3cP9Z">
                  The release of the next generation of gods is first on our
                  priority list, allowing the gods to breed and produce little
                  pharaohs and deities.
                </p>
              </div>
              <div className="styles_roadmap__content_block__2RfSP">
                <img src={phase2} width='40px' height='40px' />
                <p className="styles_roadmap__content_block_title__2pt_D">
                  Phase 2:
                </p>
                <p className="styles_roadmap__content_block_body__3cP9Z">
                  Owners of gods not only can breed but also unlock rewards by
                  mummification of gods for locked time period.
                </p>
              </div>
              <div className="styles_roadmap__content_block__2RfSP">
                <img src={phase3} width='40px' height='40px' />
                <p className="styles_roadmap__content_block_title__2pt_D">
                  Phase 3:
                </p>
                <p className="styles_roadmap__content_block_body__3cP9Z">
                  Announcing Partnerships with other NFT metaverses.
                </p>
              </div>
              <div className="styles_roadmap__content_block__2RfSP">
                <img src={phase4} width='40px' height='40px' />
                <p className="styles_roadmap__content_block_title__2pt_D">
                  Phase 4:
                </p>
                <p className="styles_roadmap__content_block_body__3cP9Z"></p>
                <ul className="styles_roadmap__content_block_body__list__fyJRH">
                  <p className="styles_roadmap__content_block_body__3cP9Z">
                    {" "}
                    releasing a video game where each God is a playable
                    characters in the Gods of Giza universe.
                  </p>
                </ul>
                <p />
              </div>
              <div className="styles_roadmap__content_block__2RfSP">
                <img src={phase5} width='40px' height='40px' />
                <p className="styles_roadmap__content_block_title__2pt_D">
                  Phase 5:
                </p>
                <p className="styles_roadmap__content_block_body__3cP9Z">
                  We consider this NFT project like any other venture, so
                  believe that the roadmap will continue to evolve over time
                  with inputs from the community, including metaverse
                  initiatives, raffles, airdrops, meme contests and more.
                  Please join our Discord server for updates and participate
                  in shaping our future roadmap.
                </p>
              </div>
            </div>
          </section>
          <section className="styles_section__1y3T-" id="nft">
            <h1 className="styles_heading__32sCL glowingText">Meet Our Gods</h1>
            <div className="styles_carousal__1_LFI">
              <div className="styles_leftarrow__3Qlf_" />
              <div className="styles_carouselContainer__22Nm5 styles_leftShift1__1MCjj">
                <div className="teamCard">
                  <div className="teamCardImage">
                    <img
                      src={t1}
                      alt
                      className="teamImg"
                    />
                  </div>
                  <h3 className="teamMember">Ramses</h3>
                  <p className="jobtile">Artist</p>
                </div>
                <div className="teamCard">
                  <div className="teamCardImage">
                    <img
                      src={t2}
                      alt
                      className="teamImg"
                    />
                  </div>
                  <h3 className="teamMember">Anubis</h3>
                  <p className="jobtile">Developer</p>
                </div>
                <div className="teamCard">
                  <div className="teamCardImage">
                    <img
                      src={t3}
                      alt
                      className="teamImg"
                    />
                  </div>
                  <h3 className="teamMember">Cleopatra</h3>
                  <p className="jobtile">Artist</p>
                </div>
              </div>
              <div className="styles_rightarrow__1o6BQ">
                <img src="/static/media/arrow.149f3512.svg" alt />
              </div>
            </div>
            {/* <div className="styles_section_footer__1QSA9 styles_section_footer_display__1dnle">
                <div className="styles_section_footer_block__3diy_">
                  MAGIC HAPPENS
                </div>
                <div className="styles_section_footer_block__3diy_">
                  BEST DAY EVER
                </div>
                <div className="styles_section_footer_block__3diy_ styles_section_footer_block_display__1Ues5">
                  DIN DIRIN DIN
                </div>
                <div className="styles_section_footer_block__3diy_ styles_section_footer_block_display__1Ues5">
                  LOVE IS REAL
                </div>
              </div>
              <div className="styles_section_footer__1QSA9">
                <div className="styles_section_footer_block__3diy_ styles_section_footer_block_display__1Ues5">
                  GODS OF GIZA
                </div>
                <div className="styles_section_footer_block__3diy_ styles_section_footer_block_display__1Ues5">
                  9,999 PHARAOHS
                </div>
                <div className="styles_section_footer_block__3diy_">
                  192 TRAITS
                </div>
                <div className="styles_section_footer_block__3diy_">
                  THE FUTURE
                </div>
              </div> */}
          </section>
          <section id="faq" className="faq">
            <h1 className="styles_heading__1kCwn glowingText">Frequently Asked Questions</h1>
            <div className="accordionContainer">
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What are NFTs?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      NFT or Non-Fungible Token is a unique crypto token attached
                      to a digital piece of artwork like a JPG, PNG, GIF, MP3 or
                      even 3D files, which records the origin, authenticity, proof
                      of ownership and transaction history of that work on the
                      blockchain. And as the name suggests, these tokens are
                      non-fungible, which means they are one of its kind and their
                      provenance cannot be duplicated or replicated. In most
                      cases, NFTs only give ownership of the digital version of
                      the artwork to the buyer and the right to trade that piece
                      on the blockchain. But in some cases, artists can also
                      provide full offline commercial rights of the artwork to
                      collectors of their NFTs, for creating derivate artwork or
                      merchandise and sell them as well. NFTs are gaining a lot of
                      popularity as the blockchain technology is used to sell
                      artwork digitally and it solves many problems faced by
                      traditional offline art collection, like high cost of
                      maintenance, storage and display real estate space,
                      insurance and security to name a few.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How do NFTs work?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      When an NFT is minted, dropped, purchased or transferred, a
                      transaction is run through a network of computers that
                      authenticate and capture a digital record of all trades ever
                      executed for that specific token. This decentralized network
                      is often referred to as a blockchain, which ensures every
                      computer involved agrees with any change to the ledger, as
                      is the case with paper documents. This process ensures
                      maximum security when trading NFTs.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>


                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How do I buy the Gods of Giza NFTs?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      You will basically need Ethereum cryptocurrency in a wallet
                      like MetaMask, because the blockchain doesn’t accept Fiat
                      currency. So you will have to first buy Ethereum from your
                      Fiat money using a crypto exchange like Coinbase, Binance or
                      similar exchange in your country. Next you will have to
                      transfer that Ethereum to your MetaMask wallet, which you
                      can then connect to this website via a browser extension
                      (preferably Chrome) and “mint” the Gods of Giza NFTs. Please
                      be aware that if you’re not part of the pre-sale allow list,
                      then sometimes the Gas Fees can shoot up considerably during
                      public sale and you might miss the opportunity to mint. So
                      if you’re early to join our Discord server, you can earn
                      yourself a spot in pre-sale where you can mint at your
                      convenience when the Gas Fees is low.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Where will I be able to see and trade my Gods of Giza NFTs?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Once the minting is complete, you will be able to see your
                      Gods of Giza NFTs in the wallet that you used for minting.
                      If you use MetaMask, you will be able to see them under the
                      NFTs tab on your MetaMask mobile app. For secondary buying
                      or selling, you will have to visit Opensea.io and connect
                      your wallet to view your NFTs under the My Profile section.
                      And always make sure to buy from the official Opensea
                      collection link posted on our Twitter or Discord.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>


                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Where to buy Gods of Giza?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p> When the time has come. You will be able to mint them
                      here.</p>
                  </AccordionItemPanel>
                </AccordionItem>


                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Can I buy more than 1 NFT?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, absolutely. This is the coolest form of NFT ever. So,
                      don't just buy them, but show them to your world too.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>


                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What is the benefit of buying back NFTs?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>There are two main benefits here. First, its reducing
                      supply will increase the price. Second, it increases
                      liquidity which reduces the risks of difficult sales.
                      Therefore, it will make this collection more profitable with
                      lower risks.</p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Why don't you burn them?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                    Are you kidding? We are talking about a piece of art here.
                    LOL. Let me explain. While buyback and burn is a common
                    strategy for many cryptocurrencies, burning NFTs is not the
                    best option because we already have a low supply of NFTs,
                    and unlike coins, these are NON-FUNGIBLE and UNDEVIABLE.
                    That means they are all unique and you cannot buy a fraction
                    amount. Thus, burning them might reduce the engagement of
                    our community. The smarter way would be to use the rebought
                    NFTs for marketing campaigns. This should create exposure
                    and demand for the NFTs, which will drive the price up more
                    effectively.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Are there any Royalty fees?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                    been sold, we will use them to: - 3% to the holders treasury
                    for buy back NFTs. - 5% for sustainable development of the
                    project. - 2% to creator
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              {/* <div className="styles_qna__34Pwe">
                  <p className="styles_question__1GGDP">What are NFTs?</p>
                  <p className="styles_answer__KlbGe">
                    NFT or Non-Fungible Token is a unique crypto token attached
                    to a digital piece of artwork like a JPG, PNG, GIF, MP3 or
                    even 3D files, which records the origin, authenticity, proof
                    of ownership and transaction history of that work on the
                    blockchain. And as the name suggests, these tokens are
                    non-fungible, which means they are one of its kind and their
                    provenance cannot be duplicated or replicated. In most
                    cases, NFTs only give ownership of the digital version of
                    the artwork to the buyer and the right to trade that piece
                    on the blockchain. But in some cases, artists can also
                    provide full offline commercial rights of the artwork to
                    collectors of their NFTs, for creating derivate artwork or
                    merchandise and sell them as well. NFTs are gaining a lot of
                    popularity as the blockchain technology is used to sell
                    artwork digitally and it solves many problems faced by
                    traditional offline art collection, like high cost of
                    maintenance, storage and display real estate space,
                    insurance and security to name a few.
                  </p>
                </div>
                <div className="styles_qna__34Pwe">
                  <p className="styles_question__1GGDP">How do NFTs work?</p>
                  <p className="styles_answer__KlbGe">
                    When an NFT is minted, dropped, purchased or transferred, a
                    transaction is run through a network of computers that
                    authenticate and capture a digital record of all trades ever
                    executed for that specific token. This decentralized network
                    is often referred to as a blockchain, which ensures every
                    computer involved agrees with any change to the ledger, as
                    is the case with paper documents. This process ensures
                    maximum security when trading NFTs.
                  </p>
                </div>
                <div className="styles_qna__34Pwe">
                  <p className="styles_question__1GGDP">
                    How do I buy the Gods of Giza NFTs?
                  </p>
                  <p className="styles_answer__KlbGe">
                    You will basically need Ethereum cryptocurrency in a wallet
                    like MetaMask, because the blockchain doesn’t accept Fiat
                    currency. So you will have to first buy Ethereum from your
                    Fiat money using a crypto exchange like Coinbase, Binance or
                    similar exchange in your country. Next you will have to
                    transfer that Ethereum to your MetaMask wallet, which you
                    can then connect to this website via a browser extension
                    (preferably Chrome) and “mint” the Gods of Giza NFTs. Please
                    be aware that if you’re not part of the pre-sale allow list,
                    then sometimes the Gas Fees can shoot up considerably during
                    public sale and you might miss the opportunity to mint. So
                    if you’re early to join our Discord server, you can earn
                    yourself a spot in pre-sale where you can mint at your
                    convenience when the Gas Fees is low.
                  </p>
                </div>
                <div className="styles_qna__34Pwe">
                  <p className="styles_question__1GGDP">
                    Where will I be able to see and trade my Gods of Giza NFTs?
                  </p>
                  <p className="styles_answer__KlbGe">
                    Once the minting is complete, you will be able to see your
                    Gods of Giza NFTs in the wallet that you used for minting.
                    If you use MetaMask, you will be able to see them under the
                    NFTs tab on your MetaMask mobile app. For secondary buying
                    or selling, you will have to visit Opensea.io and connect
                    your wallet to view your NFTs under the My Profile section.
                    And always make sure to buy from the official Opensea
                    collection link posted on our Twitter or Discord.
                  </p>
                </div>
                <div className="styles_qna__34Pwe">
                  <p className="styles_question__1GGDP">
                    Where to buy Gods of Giza?
                  </p>
                  <p className="styles_answer__KlbGe">
                    - When the time has come. You will be able to mint them
                    here.
                  </p>
                </div>
                <div className="styles_qna__34Pwe">
                  <p className="styles_question__1GGDP">
                    Can I buy more than 1 NFT?
                  </p>
                  <p className="styles_answer__KlbGe">
                    - Yes, absolutely. This is the coolest form of NFT ever. So,
                    don't just buy them, but show them to your world too.
                  </p>
                </div>
                <div className="styles_qna__34Pwe">
                  <p className="styles_question__1GGDP">
                    What is the benefit of buying back NFTs?
                  </p>
                  <p className="styles_answer__KlbGe">
                    - There are two main benefits here. First, its reducing
                    supply will increase the price. Second, it increases
                    liquidity which reduces the risks of difficult sales.
                    Therefore, it will make this collection more profitable with
                    lower risks.
                  </p>
                </div>
                <div className="styles_qna__34Pwe">
                  <p className="styles_question__1GGDP">
                    Why don't you burn them?
                  </p>
                  <p className="styles_answer__KlbGe">
                    - Are you kidding? We are talking about a piece of art here.
                    LOL. Let me explain. While buyback and burn is a common
                    strategy for many cryptocurrencies, burning NFTs is not the
                    best option because we already have a low supply of NFTs,
                    and unlike coins, these are NON-FUNGIBLE and UNDEVIABLE.
                    That means they are all unique and you cannot buy a fraction
                    amount. Thus, burning them might reduce the engagement of
                    our community. The smarter way would be to use the rebought
                    NFTs for marketing campaigns. This should create exposure
                    and demand for the NFTs, which will drive the price up more
                    effectively.
                  </p>
                </div>
                <div className="styles_qna__34Pwe">
                  <p className="styles_question__1GGDP">
                    Are there any Royalty fees?
                  </p>
                  <p className="styles_answer__KlbGe">
                    Yes, there is a 10% loyalty fee. After all Gods of Giza have
                    been sold, we will use them to: - 3% to the holders treasury
                    for buy back NFTs. - 5% for sustainable development of the
                    project. - 2% to creator
                  </p>
                </div> */}
            </div>
          </section>

          <footer className="styles_footer__2gyfK">
            <div className="styles_footer__row_flex__2Z04u">
              <svg viewBox="0 0 1320 300" className='bottomSVG'>
                <text x="50%" y="50%" dy=".35em" text-anchor="middle" className='bottomSVG'>
                  GODS OF GIZA
                </text>
              </svg>

              <nav className="styles_nav__1YM61">
                <ul className="styles_unordered__3CR8E">
                  <li>
                    <a href="#about" className="styles_link_active__2p7bx">
                      About
                    </a>
                  </li>
                  <li>
                    <a href="#community">Community</a>
                  </li>
                  <li>
                    <a href="#roadmap">Roadmap</a>
                  </li>
                  <li>
                    <a href="#faq">FAQs</a>
                  </li>
                  <li>
                  <a href="https://forms.gle/Un7ttT42p47vgRai7" target="_blank" class="custom-btn btn-7"><span>Subscribe</span></a>
                </li>
                </ul>
                <div className="styles_cross__2sUuB">X</div>
              </nav>
            </div>
            <div className="styles_footer__row_center__2MaEF">
              Copyright © 2021 | All rights reserved
            </div>
          </footer>
        </div>
      </div>
    </div>
  );

}

export default Main2;
